import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Campaigns/widgets: Now that segments and profiles have associated channels, campaigns only show related segments, profiles and Lead Scores with the same channels correspondence. `}</li>
      <li parentName="ul">{`Bug fix: Classification Fields on Aggregation Panels showing correct metrics.`}</li>
      <li parentName="ul">{`Bug fix: On v4 we can select the 24 Hour Period for Panels and Dashboards.`}</li>
      <li parentName="ul">{`Segments: Uniformed all content visualization for segments and segment Ids fields.`}</li>
      <li parentName="ul">{`Hera: Replaced the current Button usage for the new @sidetalk/react Button component.`}</li>
      <li parentName="ul">{`Smarkio: Added color information to Smarkio chatbot URL call.*`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      